<template>
  <v-card
    :style="smUp ? 'background-color: transparent; padding-top: 20px; margin-bottom: 50px':''"
    flat>
      <v-layout
        :style="smUp ? 'max-width: 85%; margin-top: 2.5%; margin-bottom: 4%': 'margin-top: 2.5%; margin-bottom: 6%'"
        wrap
        row
        :justify-center="!smUp"
        :justify-start="smUp">
        <v-flex
          xs12
          sm6>
          <v-layout
            row
            :justify-center="!smUp"
            :justify-start="smUp">
            <p
              v-text="'Operators'"
              class="view_title_text"
            />
          </v-layout>
        </v-flex>
        <v-flex
          xs12
          sm6>
          <v-layout
            row
            :justify-center="!smUp"
            :justify-end="smUp">
            <v-btn
              @click="add_operator = true"
              style="margin: 20px"
              color="primary"
              dense
              >
              Add
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout
        row
        justify-center>
        <v-layout
          row
          :class="smUp  ? 'search_user':'search_user_mobile'">
          <v-text-field
            solo
            clearable
            v-model="operator_search"
            color="primary"
            placeholder="Búsqueda de operadores"
          />
        </v-layout>
      </v-layout>
      <v-layout
        v-if="total_users > 0 && !bLoading"
        row
        justify-center>
        <p  
          :class="smUp  ? 'total_user_text':'total_user_text_mobile'"
          v-text="'There is a total of ' + total_users + ' operators. '"
        />
      </v-layout>


      <v-layout>
        <v-container>
          <!-- Start table container -->
          <div
            v-if="!bLoading">
            <operator-table 
              :items="operator_list"
              @update="get_operators"
            />
            <v-pagination 
              v-model="current_page" 
              :length="num_pages">
            </v-pagination>
          </div>
          <!-- Loading animation -->
          <v-card
            flat
            style="height: 100%; width: 100%; background-color: transparent"
            v-else>
            <v-layout
              :style="smUp ? 'max-width: 90%; margin-top: 10%':''"
              row
              justify-center>
              <v-progress-circular
                indeterminate
                rotate
                color="primary"
                size="100"
                width="1"
              />
            </v-layout>
          </v-card>
          <!-- Loading animation -->
          <!-- End table container -->
        </v-container>
      </v-layout>
      <operator-add
        :bActive="add_operator"
        @close="add_operator = false"
        @update="get_operators"
      />
  </v-card>
</template>
<script>
export default {
  data: () => ({
    current_page: 1,
    num_pages: 0,
    operator_list: [],
    add_operator: false,
    bLoading: false, 
    operator_search: '',
    total_users: 0,
  }),
  mounted () {
    this.get_operators()
  },
  watch: {
    current_page: function () {
      if (this.operator_search && !this.bLoading) {
        this.get_operators_search()
      } else if (!this.bLoading) {
        this.get_operators()
      } 
    },
    operator_search: _.debounce(function() {
      if (this.operator_search && !this.bLoading) {
        this.get_operators_search()
      } else if (!this.bLoading) {
        this.get_operators()
      }    
    }, 500)
  },
  methods: {
    get_operators () {
      this.bLoading = true
      db.get(`${uri}/v2/api/users`, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
          'Authorization': this.usr_token
        },
        params: {
          iPage: this.current_page,
          iPageSize: 15,
          iRole: 1
        }
      })
      .then(resp => {
        this.bLoading = false
        this.$store.commit('toggle_alert', {color: 'success', text: resp.data.message})
        this.num_pages = resp.data.iTotalPages
        this.total_users = resp.data.iTotalUsers
        this.operator_list = resp.data.users
      })
      .catch(err => {
        this.bLoading = false
        this.$store.commit('toggle_alert', {color: 'fail', text: err.response.data.message})
      })
    },
    get_operators_search () {
      this.bLoading = true
      db.get(`${uri}/v2/api/users`, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
          'Authorization': this.usr_token
        },
        params: {
          query: this.operator_search,
          iPage: this.current_page,
          iPageSize: 15,
          iRole: 1
        }
      })
      .then(resp => {
        this.bLoading = false
        this.$store.commit('toggle_alert', {color: 'success', text: resp.data.message})
        this.num_pages = resp.data.iTotalPages
        this.total_users = resp.data.iTotalUsers
        this.operator_list = resp.data.users
      })
      .catch(err => {
        this.bLoading = false
        this.$store.commit('toggle_alert', {color: 'fail', text: err.response.data.message})
      })
    }
  },
  computed: {
    smUp () {
      return this.$vuetify.breakpoint.smAndUp
    },
    usr_token () {
      return 'Bearer ' + this.$store.state.token
    }
  },
  components: {
    OperatorAdd: () => import('@/components/sections/Admin/Operators/OperatorAdd'),
    OperatorTable: () => import('@/components/sections/Admin/Operators/OperatorTable')
  }
}
</script>
<style scoped>
.total_user_text {
  font-family: "Open sans", sans-serif; 
  font-size: 15px; 
  margin-right: 10%
}
.total_user_text_mobile {
  max-width: 90%;
  text-align: center;
  font-family: "Open sans", sans-serif; 
  font-size: 10px; 
}
.search_user {
  margin-right: 10%
}
.search_user_mobile {
  max-width: 80%
}
</style>